import React, { Component } from 'react';
import MetaPost from './MetaPost'
import Img from 'gatsby-image'
import './blog.css';
import './bsa.css';
import Share from './share'
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import Recommended from './recommended';
import ReactDOMServer from 'react-dom/server';
import Subscribe from './subscribe';
import UdemySide from './udemySide';
import BsaAd from './carbonads/BsaAd';
import Primis from './carbonads/Primis';
import BottomPosts from './BottomPosts';

class BlogMarkdown extends Component {
    state = {
        scrollHeight: 0,
        similarPosts: this.props.similar.nodes.sort(function () {
            return 0.5 - Math.random()
        }
        ).slice(0, 9),
        topPosts: this.props.similar.nodes.sort(function () {
            return 0.1 - Math.random()
        }
        ).slice(0, 6),
    }
    componentDidMount() {
        // window.onscroll = this.updateScroll;

        const s = this.article.getElementsByTagName('p')[0];
        this.attachComponent(s);
    }


    attachComponent(el) {
        let com = ReactDOMServer.renderToString(<Recommended tags={this.props.data.markdownRemark.frontmatter.tags} />);
        el.insertAdjacentHTML('afterend', com);
    }


    updateScroll = () => {
        var winScroll =
            document.body.scrollTop || document.documentElement.scrollTop
        var height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight
        var scrolled = (winScroll / height) * 100
        this.setState({
            scrollHeight: scrolled,
        })
        if (this.state.scrollHeight > 94) {
            this.setState({
                scrollHeight: 100,
            })
        }
    }

    componentWillUnmount() {
        // window.onscroll = null;
    }
    render() {
        const post = this.props.data.markdownRemark
        const similar = this.props.similar
        const url = this.props.data.site.siteMetadata.url
        const pathname = this.props.location.pathname
        const { title, date, thumbnail, image, course, tags } = post.frontmatter
        // const { next, prev } = this.props.pageContext;
        const author = this.props.data.site.siteMetadata.author
        const seoTitle = `${title} - ${course && course}`;
        const showAds = tags[0] === `blogging`;
        return (
            <div className="blog-container">
                <MetaPost
                    title={this.props.tag ? seoTitle : title}
                    description={post.excerpt}
                    date={date}
                    url={url}
                    pathname={pathname}
                    thumbnail={this.props.thumbnail || (thumbnail && url + thumbnail)}
                    dateMod={post.modifiedTime}
                    datePub={post.birthTime}
                />
                <div className="progress-bar">
                    <span
                        className="bar"
                        style={{ width: this.state.scrollHeight + '%' }}
                    />
                </div>
                {/* <div className="bsa-leaderb">
                    <div>
                        <div id="bsa-zone_1603815949728-5_123456"></div>
                    </div>
                </div> */}
                <div className="blog-wrapper">

                    {/* <div className="left-side-bx blog-column">
                        <Subscribe />

                        {!showAds &&
                            similar.nodes.length > 1 && <div className="similar-articles dec-height">
                                <h4>Related tutorials</h4>
                                {this.state.similarPosts.map((node) => {
                                    if (node.frontmatter.title !== title) {
                                        return <Link to={node.fields.slug} key={node.fields.slug}>{node.frontmatter.title}</Link>
                                    }
                                })}
                            </div>
                        }
                    </div> */}
                    <main className="main-box">
                        <article>
                            <header>
                                {/* {date && <time dateTime={date}>{date}</time>}

                                <a
                                    style={{ color: ' rgb(14, 86, 140)', float: "right" }}
                                    href="https://twitter.com/saigowthamr"
                                >
                                    {author}
                                </a> */}

                                {date && <time dateTime={date}>{date}</time>}
                                <span style={{ float: "right" }}> &nbsp; Author {' '}- &nbsp;
                                    <a
                                        style={{ color: ' rgb(14, 86, 140)' }}
                                        href="https://twitter.com/saigowthamr"
                                    >
                                        {author}
                                    </a></span>

                                <h1 >{title}</h1>

                                {/* <span className="bread-crumbs"><Link to="/">Home</Link> {" » "}<Link
                                    to={`/tutorials/${tags[0]}/`} >{tags[0]}</Link>{" » "}{title}</span> */}
                                <div className="post-meta">
                                    <strong><Link to={`/tutorials/${kebabCase(tags[0])}/`} className="post-tag">{tags[0]}</Link></strong>

                                    <strong className="readtime">{post.timeToRead && post.timeToRead + "min read"}
                                    </strong>
                                </div>
                                {/* {image && <Img fluid={image.childImageSharp.fluid || image} alt={title} />} */}
                            </header>
                            <div
                                className="content-render"
                                style={{ marginTop: '4.4rem' }}
                                ref={node => this.article = node}
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />

                            {/* ReactGo_S2S_Sidebar_ROS_Pos2 */}
                            {/* <p style={{ textAlign: "center", margin: "1rem" }}>Advertisement</p> */}
                            <div id="bsa-zone_1658133888783-9_123456" style={{ margin: "20px auto 25px" }}></div>
                            <div className="mobile-social">
                                <Share
                                    title={`${title} - ${course ? course : 'Reactgo'}`}
                                    url={url}
                                    pathname={pathname}
                                />
                            </div>
                            {/* {!showAds && <GoogleSimlarad wrapperDivStyle={{ marginBottom: '40px', marginTop: "25px" }} />} */}
                        </article>
                    </main>
                    <div className="aside-box">
                        {/* <Subscribe /> */}
                        {/* bsa pos1 */}
                        {/* <Primis /> */}
                        <div id="bsa-zone_1603816032622-2_123456" className="bsa-sidebar"></div>
                        {!showAds &&
                            similar.nodes.length > 1 && <div className="similar-articles dec-height">
                                <h4>Similar tutorials</h4>
                                {this.state.topPosts.map((node) => {
                                    if (node.frontmatter.title !== title) {
                                        return <Link to={node.fields.slug} key={node.fields.slug}>{node.frontmatter.title}</Link>
                                    }
                                })}
                            </div>
                        }
                        {/* <p style={{ textAlign: "center", margin: "1rem" }}>Advertisement</p> */}
                        {/* <div id="bsa-zone_1603816032622-2_123456" className="bsa-sidebar"></div> */}
                        <div id="bsa-zone_1603816109811-9_123456" className="bsa-sidebar"></div>
                    </div>

                </div >
                <div className="desktop-social">
                    <Share
                        title={`${title} - ${course ? course : 'Reactgo'}`}
                        url={url}
                        pathname={pathname}
                    />
                </div>
                {/* <NextPost prev={prev} next={next} /> */}
                <div className='top-courses'>
                    <h2 style={{ textAlign: "center", padding: "1rem" }}>Css Tutorials & Demos</h2>
                    <BottomPosts
                        title="How rotate an image continuously in CSS"
                        url="/rotate-image-css/"
                        content="In this demo, we are going to learn about how to rotate an image continuously using the css animations."
                    />
                    {/* <p style={{ textAlign: "center", margin: "1rem" }}>Advertisement</p> */}
                    <BsaAd>
                        <div id="bsa-zone_1603815917966-6_123456" style={{ marginBottom: "2rem" }} className='bsa-leaderb'></div>
                    </BsaAd>
                    <BottomPosts
                        title="How to create a Instagram login Page"
                        url="/create-instagram-login-page/"
                        content="In this demo, i will show you how to create a instagram login page using html and css."
                    />
                    <BottomPosts
                        title="How to create a pulse animation in CSS"
                        url="/css-pulse-animation/"
                        content="In this demo, i will show you how to create a pulse animation using css."
                    />
                    <BottomPosts
                        title="Creating a snowfall animation using css and JavaScript"
                        url="/css-snow-animation/"
                        content="In this demo, i will show you how to create a snow fall animation using css and JavaScript."
                    />
                </div>
                {/* <p style={{ textAlign: "center", marginTop: "-2rem" }}>Advertisement</p> */}
                {/* bsa leader board bottom */}
                <div className="leader-board-2" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <div>
                        <div id="bsa-zone_1603816159461-6_123456"></div>
                    </div>
                </div>
                <div className="top-courses">
                    <h2 style={{ textAlign: "center", padding: "1rem" }}>Top Udemy Courses</h2>
                    <div className="top-course-box">
                        <UdemySide tags={['javascript']} />
                        <UdemySide tags={['react']} />
                        <UdemySide tags={['vuejs']} />
                    </div>
                    {/* bsa leader board bottom */}
                    {/* <p style={{ textAlign: "center", marginTop: "2rem" }}>Advertisement</p> */}
                    <div className="leader-board-2">
                        <div>
                            <div id="bsa-zone_1603815949728-5_123456"></div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}

export default BlogMarkdown;
