import React from "react";


class BsaAd extends React.Component {

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            if (!document.getElementById("myscr")) {
                this.loadAd();
            } else if (window.googletag) {
                window.googletag.pubads().refresh();
            }
        }
    }

    loadAd = () => {
        var bsa_optimize = document.createElement('script');
        bsa_optimize.type = 'text/javascript';
        bsa_optimize.async = true;
        bsa_optimize.id = 'myscr';
        bsa_optimize.src = 'https://cdn4.buysellads.net/pub/reactgo.js?' + (new Date() - new Date() % 600000);
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(bsa_optimize);
    }

    render() {
        return this.props.children
    }
}

export default BsaAd;