import React, { Component } from 'react';
import './carbon.css';

class Primis extends Component {

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            window.top.sekindoFlowingPlayerOn = false;
            this.loadAd();
        }
    }

    loadAd = () => {
        const script = document.createElement("script");
        script.src = "https://live.primis.tech/live/liveView.php?s=107313&cbuster=[CACHE_BUSTER]&pubUrl=[PAGE_URL_ENCODED]&subId=[SUBID_ENCODED]";
        script.id = "primis";
        // script.async = true;
        this.carbon.appendChild(script);
    }

    componentWillUnmount() {
        const el = document.getElementById("primis");
        if (el) {
            el.remove();
        }
    }

    render() {
        return (

            <div className="normal" id="sair">
                <div className="normal" id="sair"
                    ref={(el) => this.carbon = el}></div>
                {/* {process.env.NODE_ENV === 'production' && <script defer type="text/javascript" language="javascript" src="https://live.primis.tech/live/liveView.php?s=107313&cbuster=[CACHE_BUSTER]&pubUrl=[PAGE_URL_ENCODED]&subId=[SUBID_ENCODED]"></script>} */}

            </div>

        )
    }
}

export default Primis;
