import React from "react";

function getBookList() {
  const affList = [
    {
      name: "vuejs",
      refUrl:
        "https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.995016&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fvuejs-2-the-complete-guide%2F",
      courseName: "Vue - The Complete Guide (w/ Router, Vuex, Composition API)",
      img: "https://i.udemycdn.com/course/480x270/995016_ebf4.jpg",
      hours: "31.5",
      enroll: "203,937",
      price: "$14.99"
    },

    {
      name: "react",
      refUrl:
        "https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.1362070&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Freact-the-complete-guide-incl-redux%2F",
      img: "https://i.udemycdn.com/course/480x270/1362070_b9a1_2.jpg",
      courseName:
        "React - The Complete Guide (incl Hooks, React Router, Redux)",
      hours: "49",
      enroll: "631,582",
      price: "$24.99"

    },
    {
      name: "javascript",
      refUrl:
        "https://click.linksynergy.com/link?id=wGVEwkf7KWY&offerid=507388.2508942&type=2&murl=https%3A%2F%2Fwww.udemy.com%2Fcourse%2Fjavascript-the-complete-guide-2020-beginner-advanced%2F",
      img: "https://i.udemycdn.com/course/480x270/2508942_11d3.jpg",
      courseName: "JavaScript - The Complete Guide 2023 (Beginner + Advanced)",
      hours: "52",
      enroll: "116,648",
      price: "$14.99"
    },
  ];

  return affList;
}

export default getBookList;
