import React from 'react';
import udemyList from '../../usage/udemy-afflist';
import './index.css';

function UdemySide(props) {

    const { tags } = props;
    const affLink = udemyList().filter((aff) => {
        return aff.name === tags[0]
    })
    if (affLink[0]) {

        return (
            <div style={{ textAlign: "center" }} className="aam">
                <div className="course-container">
                    <a href={affLink[0].refUrl} target="_blank" rel="nofollow noopener noreferrer">
                        <img src={affLink[0].img} alt={affLink[0].courseName} />
                    </a>
                    <div className="description">
                        <h5 className="name">{affLink[0].courseName}</h5>
                        <div style={{
                            marginTop: "-13px",
                            fontSize: "14px",
                            paddingBottom: "1rem",
                        }}>{affLink[0].enroll} students enrolled</div>
                        <div style={{
                            marginTop: "-13px",
                            fontSize: "14px",
                            paddingBottom: "1.4rem",
                        }}>{affLink[0].hours} hours of video content</div>
                        <a href={affLink[0].refUrl} className="course-btn" target="_blank" rel="nofollow noopener noreferrer"> {affLink[0].price} FROM UDEMY</a>
                    </div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default UdemySide;