import { Link } from 'gatsby';
import React, { Component } from 'react';
import './index.css'

class BottomPosts extends Component {

    render() {
        return (
            <div className="btm-content">
                <Link to={this.props.url} className="btm-link"><h2>{this.props.title}</h2></Link>
                <p>{this.props.content}</p>
            </div>
        )
    }
}

export default BottomPosts;