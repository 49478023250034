import React from 'react'
import BlogMarkDown from '../components/blogMarkdown'
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';
// import Jsimg from '../img/js.jpg';

class Post extends React.Component {
  render() {

    const { url } = this.props.data.site.siteMetadata
    const similar = this.props.data.allMarkdownRemark;
    const thumbnail =
      this.props.data.markdownRemark.frontmatter.image ?
        this.props.data.markdownRemark.frontmatter.image.childImageSharp.resize.src : "";

    return (
      <Layout>
        <Header siteTitle={'Reactgo'} />
        <BlogMarkDown
          bottom
          thumbnail={thumbnail ? url + thumbnail : url + '/img/reactgo-thumbnail.png'}
          data={this.props.data}
          location={this.props.location}
          pageContext={this.props.pageContext}
          similar={similar}
        />
        <Footer siteTitle={'Reactgo'} />
      </Layout>
    )
  }
}

export default Post
export const pageQuery = graphql`
  query BlogPostQuery($slug: String!,$tag:String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      timeToRead
      frontmatter {
        title
        date(formatString: "ll")
        tags
        image {
          childImageSharp {
            resize(width: 1000, height: 500) {
              src
            }
            fluid(maxWidth: 786, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
    site {
      siteMetadata {
        url
        author
      }
    }
  allMarkdownRemark(filter: {frontmatter: {tags: {eq: $tag}}}) {
    nodes {
      frontmatter {
        title
      }
      fields{
        slug
      }
    }
  }
  }
`
